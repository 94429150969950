<template>
  <div>
    <b-modal
      id="bill-to-pay-product-modal"
      hide-header
      hide-footer
      centered
      size="lg"
      @hidden="onHidden"
      @show="onShow"
    >
      <div class="modal-header">
        <div class="modal-title">Conta a pagar (entrada do estoque)</div>
        <span class="icon-box"><v-close class="icon stroke" @click="closeModal" /></span>
      </div>

      <div class="md-content">
        <div class="row">

          <div class="col-4">
            <b-form-group>
              <label for="field_date" id="title-styles">Data</label>
              <date-picker
                class="full"
                v-model="form.service_date"
                format="DD/MM/YYYY"
                placeholder="DD/MM/AAAA"
                :clearable="false"
                :lang="langDatePicker"
              />
              <div v-if="validated && !form.service_date" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-form-group>
          </div>

          <div class="col-8">
            <b-form-group>
              <label for="field_discount" id="title-styles">
                Competência
                <span class="help">(opcional)</span>
              </label>
              <b-form-input
                autocomplete="off"
                class="form-control"
                placeholder="Descrever"
                v-model="form.competence"
              />
            </b-form-group>
          </div>
          <div class="col-12">
            <b-table-simple class="table">
              <thead>
              <tr>
                <td class="background head">Cód. de barras</td>
                <td class="background head">Lote</td>
                <td class="background head">Nome</td>
                <td class="background head">Qtde.</td>
                <td class="background head">Venda unit.</td>
                <td class="background head">Custo unit.</td>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(transaction, index) in form.transactions" :key="index">
                <td class="dashed">{{ transaction.barcode ?? '-' }}</td>
                <td class="dashed">{{ transaction.batch }}</td>
                <td :class="{ product: true,  dashed: index !== 0 }">{{ transaction.product.name }}</td>
                <td class="dashed">{{ transaction.quantity }}</td>
                <td class="dashed">{{ parseNumberToMoney(transaction.sale_price) }}</td>
                <td class="dashed">{{ parseNumberToMoney(transaction.purchase_price) }}</td>
              </tr>
              <tr class="divide-bottom">
                <td class="background head" colspan="5">Valor total dos produtos</td>
                <td class="background total">{{ parseNumberToMoney(this.productsCost) }}</td>
              </tr>
              <tr class="divide-bottom">
                <td class="background head" colspan="5">Valor total de despesas da nota fiscal</td>
                <td class="background total">{{ parseNumberToMoney(this.otherCosts) }}</td>
              </tr>
              <tr>
                <td class="background head strong" colspan="5">Valor total</td>
                <td class="background total strong">{{ parseNumberToMoney(this.getTotalAmount()) }}</td>
              </tr>
              </tbody>
            </b-table-simple>
          </div>
          <div class="col-12 mb-4">
            <WarehouseEntryInfo :visible="false" />
          </div>
          <div class="col-12">
            <b-form-group>
              <label for="field_discount" id="title-styles">Descrição</label>
              <b-form-input
                autocomplete="off"
                class="form-control"
                v-model="form.description"
              />
              <div v-if="validated && !form.description" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-form-group>
          </div>
        </div>

        <v-payments
          :validated="validated"
          :bill="form"
        />

        <div class="action-button-wrapper">
          <b-button class="action-button" variant="link" size="lg" @click="closeModal">
            Voltar a entrada
          </b-button>
          <b-button
            class="action-button"
            variant="primary"
            size="lg"
            @click="createBillToPay"
          >
            {{ !form.id ? 'Efetuar entrada' : 'Atualizar entrada' }}
          </b-button>
        </div>
      </div>

    </b-modal>
  </div>
</template>
<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'
import Close from '@/assets/icons/close.svg'
import Payments from '@/components/Financial/Payments'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    'v-close': Close,
    'v-payments': Payments,
    WarehouseEntryInfo: () => import('@/components/Warehouse/WarehouseEntryInfo')
  },
  props: {
    billToPay: Object
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      validated: false,
      form: this.getDefaultForm(),
      paymentMethods: [],
      bankAccounts: [],
      category: null,
      currencies: [{ label: 'R$', value: 'R$' }, { label: '%', value: '%' }],
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      }
    }
  },
  computed: {
    ...mapGetters('warehouse', ['getSelectedEntry']),
    otherCosts() {
      if (this.getSelectedEntry) {
        return this.getSelectedEntry.delivery_value
          + this.getSelectedEntry.insurance_value
          + this.getSelectedEntry.other_expenses_value
          - this.getSelectedEntry.discount
      }
      return 0
    },
    productsCost() {
      return this.form
        ?.transactions
        ?.reduce((acc, p) => acc + (p.quantity * p.purchase_price), 0)
    }
  },
  async mounted() {
    this.category = await this.getProductCategory()
  },
  methods: {
    ...mapActions('warehouse', ['updateBillToPay']),
    parseNumberToMoney,
    async onShow() {
    },
    onHidden() {
      this.validated = false
      this.form = this.getDefaultForm()
      this.$emit('onHide')
    },
    closeModal() {
      this.$bvModal.hide('bill-to-pay-product-modal')
    },
    getDefaultForm() {
      return {
        id: null,
        type: 'bill_to_pay',
        category: this.category,
        patient: null,
        person: null,
        description: null,
        competence: null,
        payments: [],
        transactions: []
      }
    },
    getTotalAmount() {
      return this.productsCost + this.otherCosts
    },
    getProductCategory() {
      return new Promise((resolve, reject) => {
        this.api.getProductCategory(this.clinic.id)
          .then(res => resolve(res.data))
          .catch(reject)
      })
    },
    parseFormData() {
      return {
        type: this.form.type,
        clinic_id: this.clinic.id,
        category_id: this.form.category.id,
        costCenters: this.form.costCenters,
        patient_id: this.form.patient ? this.form.patient.id : null,
        person_id: this.form.person ? this.form.person.id : null,
        competence: this.form.competence || null,
        description: this.form.description,
        service_date: this.form.service_date,
        amount: this.form.amount,
        final_amount: this.form.final_amount,
        payments: this.form.payments.map(payment => ({
          id: payment.id,
          amount: payment.amount,
          due_date: payment.due_date,
          bank_account_id: payment.bank_account.id,
          payment_method_id: payment.payment_method.id,
          installments: payment.installments
        })),
        billProducts: this.form.transactions.map(t => ({
          product_id: t.product.id,
          quantity: t.quantity,
          amount: t.purchase_price
        }))
      }
    },
    isValidForm() {
      this.validated = true
      return (
        this.form.category &&
        this.form.description &&
        this.form.payments.every(payment =>
          payment.payment_method &&
          payment.bank_account &&
          payment.amount &&
          payment.due_date
        ) &&
        this.isValidAmount()
      )
    },
    isValidAmount() {
      const total = this.form.payments.reduce((acc, payment) => {
        acc += payment.amount
        return acc
      }, 0)
      if (parseFloat(total.toFixed(2)) !== parseFloat(this.getTotalAmount().toFixed(2))) {
        this.$toast.warning('A soma dos métodos de pagamento deve ser igual ao valor total')
        return false
      }
      return true
    },
    async createBillToPay() {
      this.form.category = this.category
      if (!this.isValidForm()) return
      const data = this.parseFormData()
      await this.updateBillToPay(data)
      this.$bvModal.hide('bill-to-pay-product-modal')
      this.$emit('onFinish')
    }
  },
  watch: {
    async billToPay(value) {
      if(value.category){
        this.category = this.billToPay.category;
      }
      
      if (value) {
        this.form = {
          ...this.form,
          ...value,
          service_date: new Date(value.service_date),
          amount: this.getTotalAmount(),
          final_amount: this.getTotalAmount(),
          costCenters: value.costCenters
        }
      }
    },
    async getSelectedEntry(value) {
      if (value?.transactions) {
        this.form.transactions = value.transactions
      }
    }
  }
}
</script>
<style lang="scss">
#bill-to-pay-product-modal {
  .modal-content {
    max-width: 750px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);

    .modal-body {
      padding: 0;

      .modal-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;

        .modal-title {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
        }

        .icon {
          height: 24px;
          width: 24px;
          fill: var(--neutral-500);
        }
      }

      .md-content {
        padding: 24px 24px 0 24px;

        .table {
          margin: 0 0 32px 0;

          td {
            border: none;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: var(--type-active);
          }

          .background {
            background-color: var(--neutral-100);
          }

          .head {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: var(--dark-blue);

            &.strong {
              font-weight: 700;
            }
          }

          .product {
            font-weight: 600;
            color: var(--neutral-700);
          }

          .dashed {
            border-top: 1px dashed var(--neutral-300);
          }

          .total {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: var(--neutral-700);

            &.strong {
              font-weight: 700;
            }
          }

          .divide-bottom {
            border-bottom: 1px solid var(--neutral-300);
          }
        }

        .action-button-wrapper {
          width: 100%;
          text-align: right;

          .action-button {
            margin: 20px 0;
          }
        }
      }
    }
  }
}
</style>
